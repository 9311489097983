import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import realmConnection from '@/views/habit/realm'

export default function useProcessAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { createItem, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const isSubmitting = ref(false)
  const collection = 'process'

  const storeData = async payload => {
    isSubmitting.value = true

    payload.client_id = ObjectId(userData.client.$oid)
    payload.metadata = payload.metadata?.map(e => ObjectId(e)) || []

    try {
      await createItem({ collection, payload })
      showSuccessMessage(i18n.t('message.form_success'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.process_error'))
    } finally {
      router.push({ name: 'organization-process-list' })
      isSubmitting.value = false
    }
  }

  return {
    storeData,
    isSubmitting,
  }
}
